import * as React from "react";

import Puzzle from "../../Puzzle";

import kaluze from "../../images/kaluze.jpg";
import map from "../../images/m2.png";
import audio from "../../../static/a2.mp3";

const Page = () => {
  return (
    <Puzzle
      title="Riddle 2"
      author="Author: Alicja Patanowska"
      imageSrc={kaluze}
      imageAlt="kałuża na chodniku"
      mapSrc={map}
      mapAlt="Punkt zaznaczony na mapie"
      // audio={audio}
      language="en"
      languageUrl={"/zagadki/lwpUGPgfu5"}
    >
      <div>
        <p>
          Wait for the rain. Puddles will allow you to better understand the
          city you live in.
        </p>
        <p>Why puddles?</p>
        <p>
          Where there is clay, there are also puddles. Clay is a loamy
          sedimentary rock with low permeability, thanks to which water remains
          on its surface for a long time. Both Wrocław and the entire region
          were built on soils with a high clay content, and clays, along with
          quartz and feldspar, are the main clay material used to make ceramics.
          This is the reason why the Lower Silesia province is called the
          ceramic province.
        </p>
        <p>Why water?</p>
        <p>
          Let's go back for a moment to the second half of the 1970s, when it
          was first noticed that the neighbouring yard of the SIC! art gallery
          is an interspecific ecosystem. It was the time when the landscape
          architect Janusz Szymański, responsible for the green design of the
          Kościuszko Housing District, drew attention to the role of water as an
          integral part of the city's biosphere. The district he was supposed to
          develop was "the first case of implementing water tables with
          fountains inside the blocks of flats, used as an experiment”. The
          starting point for his considerations was the assumption that “the
          interior (…) should fulfill the tasks that in other, better
          conditions, are fulfilled by a backyard garden”.
        </p>
        <p>
          The amount of water on our planet is constant, only its distribution
          changes, and so does the natural environment. “Concrete deserts”, as
          tend to call the cities tightly lined with cobblestones, asphalt and
          concrete slabs together with sealed river beds, contribute to reducing
          the retention of rainwater to only about 6%. More and more frequent
          downpours, which are one of the consequences of climate change, flow
          to the sewage system instead of seeping into the soil. Floods and
          local flooding related to the runoff of rainwater are becoming a more
          and more frequent phenomenon in Polish cities. Urban vegetation,
          ponds, puddles and green areas allow to absorb water to some extent,
          protecting us against floods and drought. That is why it is so
          important to remove the excess of concrete from the cities in order to
          enable water accumulation (retention), seepage into the ground
          (infiltration), pre-treatment, and evaporation.
        </p>
        <p>
          Today, in our households, we use only drinking water – not only for
          drinking and personal hygiene, but also for flushing toilets, watering
          plants, washing cars and other surfaces. Unfortunately, Poland does
          not have large resources of drinking water. On a global scale, its
          amount is compared to the resources of Egypt. It is estimated that as
          early as around 2050 we may begin to feel the effects of limited
          access to drinking water. Nature itself offers us the solution to this
          problem.
        </p>
        <p>
          All living organisms on Earth need water. From today onwards, let
          every puddle we come across remind us of this fact.
        </p>
      </div>
    </Puzzle>
  );
};

export default Page;
